@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
}


div{
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',"PT Serif", serif,
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  
  /* background-color: rgb(95, 85, 85); */
  background-color: #062052;
  color: black;
  /* min-height: 18vh; */
  /* min-height: 12vh; */
}
.full-width{
  margin: auto;
  max-width: 100%;
}

.footer{
  
  /* background-color: rgb(95, 85, 85); */
  background-color: #272828;
  min-height: 50vh;
  margin: 0;
  padding: 0;
}

/* new */


.bg-blue {
  background-color: rgb(196, 226, 255);
}

.border-bottom-dark {
  border-bottom: 1px solid rgb(41, 41, 41);
}

.aside-buttons > li {
  margin-bottom: 10px;
}

.border-top-dark {
  border-top: 1px solid rgba(230, 230, 230, 1);
}

.sticky-aside {
  position: sticky;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;  /* width: 20%; adjust the width as needed */
}

.para-font{
  font-size: 1.35rem;
  line-height: 2.1rem;
}

@media screen and (max-width: 660px) {
  .para-font {
    font-size: 0.9rem;
    line-height: 1.2rem;
    word-spacing: 0px;
  }
}

@media screen and (max-width: 769px) {
  .aside-tab {
    display: none;
  }
}

.modal-overlay {
  /* Styles for the overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-padding {
  padding-inline: 1rem;
}

@media screen and (min-width:600px) {
  .list-padding {
      padding-inline: 2rem;
  }
}
@media screen and (min-width:768px) {
  .list-padding {
      padding-inline: 3rem;
  }
}

@media screen and (min-width:900px) {
  .list-padding {
      padding-inline: 3.5rem;
  }
}
@media screen and (min-width:1024px) {
  .list-padding {
      padding-inline: 2rem;
  }
}
@media screen and (min-width:1280px) {
  .list-padding {
      padding-inline: 1.8rem;
  }
}

.blur-bg {
  filter: blur(5px); /* Apply blur effect to the softwares page background */
}

@media only screen and (max-width: 600px) {
  .legend {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .legend {
    font-size: 16px;
    line-height: 1.2;
  }
}

ul.thumbs {
  visibility: hidden;
}

 /* Sidebar */

 .mini-sidebar {
  width: 60px; /* Adjust the width as needed */
}

.mini-sidebar .text-label {
  display: none;
}

.mini-sidebar .icon-label {
  text-align: center;
}

#meetingSDKElement {
  width: 100vw;
  height: 100vh;
 }

 /* FOr adding line height in test editors text  */
#textLineHeight {
  line-height: 1.75rem;
}
 
.quill-container {
  position: relative;
  /* height: 70vh;  */
  /* overflow-y: auto; */
}

.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white; /* Match your page background */
}